<template>
  <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
    <!-- <a-form-model-item label="商户编号" prop="merchantId">
      <a-input v-model="form.merchantId" />
    </a-form-model-item>
    <a-form-model-item label="渠道编码" prop="code">
      <a-input v-model="form.code" />
    </a-form-model-item>
    <a-form-model-item label="渠道费率" prop="feeRate">
      <div class="components-input-demo-presuffix">
        <a-input v-model="form.feeRate" type="number" suffix="%" />
      </div>
    </a-form-model-item> -->
    <a-form-model-item label="开启状态" prop="statusChecked" @change="onChange">
      <a-switch v-model="form.statusChecked" checked-children="开" un-checked-children="关" />
    </a-form-model-item>
    <a-form-model-item label="APPID" prop="configObj.appId">
      <a-input v-model="form.configObj.appId" />
    </a-form-model-item>
    <a-form-model-item label="API version" prop="configObj.apiVersion">
      <a-input v-model="form.configObj.apiVersion" disabled />
    </a-form-model-item>
    <a-form-model-item label="apiclient_key.perm证书" prop="configObj.privateKeyContent">
      <a-input v-model="form.configObj.privateKeyContent" type="textarea" />
      <!-- @change="handleChange($event, 1)" -->
      <a-upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" :multiple="false" 
        :accept="fileAccept"
        @beforeUpload="pemFileBeforeUpload"
        :customRequest="privateKeyUpload">
        <a-button type="primary"> <a-icon type="upload" /> 点击上传 </a-button>
      </a-upload>
    </a-form-model-item>
    <a-form-model-item label="apiclient_cert.perm证书" prop="configObj.privateCertContent" >
      <a-input v-model="form.configObj.privateCertContent" type="textarea" />
      <a-upload action="https://www.mocky.io/v2/5cc8019d300000980a055e76" :multiple="false"
        :accept="fileAccept"
        @beforeUpload="pemFileBeforeUpload"
        :customRequest="privateCertUpload">
        <a-button type="primary"> <a-icon type="upload" /> 点击上传 </a-button>
      </a-upload>
    </a-form-model-item>
    <a-form-model-item label="API V3密钥" prop="configObj.apiV3Key">
      <a-input v-model="form.configObj.apiV3Key" />
    </a-form-model-item>
    <!-- <a-form-model-item label="备注" prop="remark">
      <a-input v-model="form.remark" type="textarea" />
    </a-form-model-item> -->
    <div class='foot-btn'>
      <a-button :style="{ marginRight: '8px' }" @click='onClose'>
        取消
      </a-button>
      <a-button type='primary' @click="handleSubmit" :loading="isloading">
        确定
      </a-button>
    </div>
  </a-form-model>
</template>
<script>
import * as paychannel from '@/api/pay/pay.js'

export default {
  props: {
    id: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isloading: false,
      form: {
        id: '',
        code: 'WECHAT',
        // feeRate: '',
        // merchantId: '26',
        // appId: '',
        config: '', // 渠道配置的 json 字符串
        configObj: {
          "appId": "",
          "mchId": "",
          "apiVersion": "V3",
          "privateKeyContent": "",
          "privateCertContent": "",
          "apiV3Key": ""
        },
        status: 0,
        statusChecked: false,
        remark: ''
      },
    
      fileAccept: ".pem",
      rules: {
        /*
        appId: [
          { required: true, message: '请输入公众号APPID' },
        ],
        code: [
          { required: true, message: '请输入渠道编码' },
        ],
        feeRate: [
          { required: true, message: '请输入渠道费率' },
        ],
        merchantId: [
          { required: true, message: '请选择商户id' },
        ],**/
        status: [
          { required: true, message: '请选择选择开启状态' },
        ],
        configObj: {
          appId: [
            { required: true, message: '请输入appId' }
          ],
          apiV3Key: [
            { required: true, message: '请输入apiV3Key' }
          ],
          privateKeyContent: [
            { required: true, message: '请输入privateKeyContent' }
          ],
          privateCertContent: [
            { required: true, message: '请输入privateCertContent' }
          ],
        }
      }
    }
  },
  watch: {
    id(val) {
      if(val){
        this.getInfo(val)
      }else{
        this.onClearForm()
      }
    }
  },
  methods: {
    /**
     * 根据id获取详情
     */
     getInfo(id) {
      if(!id) return
      paychannel.detailByMId({
        code: '',
        merchantId: 1601604850
      }).then(res => {
        window.console.log('res', res)
        if (res.success) {
          if(res.data.id) {
            let obj = res.data
            obj.statusChecked = false
            obj.status === 1 ? obj.statusChecked = true :
            obj.status === 0 ? obj.statusChecked = false : obj.statusChecked = false
            this.form = obj
            this.form.configObj = JSON.parse(obj.config)
          }
        }
      })
    },
    /**switch
     * 切换商户号状态 开启或者关闭
     */
     onChange(e) {
      let status = 0
      e === true ? status = 1 :
        e === false ? status = 0 : ''
      this.form.status = status
    },

    handleSubmit(e) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let apiUrl = ''
          if (this.id) {
            this.form.id = this.id
            apiUrl = paychannel.edit
          } else {
            apiUrl = paychannel.add
          }
          let data = JSON.parse(JSON.stringify(this.form))
          data.config = JSON.stringify(this.form.configObj);
          delete data.configObj
          delete data.statusChecked

          this.isloading = true
          apiUrl(data).then(res => {
            this.isloading = false
            if (res.success) {
              this.$message.success('操作成功')
              // console.log('this.$parent 111',this.$parent)
              // this.$parent.$children.close() // TODO 无法关闭
              this.$emit('closeDrawer') // 通知 父组件 关闭 弹窗
              this.$emit('updateList') // 通知 爷组件 更新列表数据

              this.onClearForm()
            } else {
              this.$message.error(res.message)
            }
          }).catch(err=>{
            this.isloading = false
          })
        } else {
          return false;
        }
      });
    },
    /****************start********************** 上传文件 ***************************************/
    pemFileBeforeUpload(file) {
      let format = '.' + file.name.split(".")[1];
      if (format !== this.fileAccept) {
        this.$message.error('请上传指定格式"' + this.fileAccept + '"文件');
        return false;
      }
      let isRightSize = file.size / 1024 / 1024 < 2
      if (!isRightSize) {
        this.$message.error('文件大小超过 2MB')
      }
      return isRightSize
    },
    privateKeyUpload(event) {
      const readFile = new FileReader()
      readFile.onload = (e) => {
        this.form.configObj.privateKeyContent = e.target.result
      }
      readFile.readAsText(event.file);
    },
    privateCertUpload(event) {
      const readFile = new FileReader()
      readFile.onload = (e) => {
        this.form.configObj.privateCertContent = e.target.result
      }
      readFile.readAsText(event.file);
    },
    /****************end********************** 上传文件 ***************************************/
    onClearForm() {
      this.form = {
        id: '',
        code: 'WECHAT',
        config: '', // 渠道配置的 json 字符串
        configObj: {
          "appId": "",
          "mchId": "",
          "apiVersion": "V3",
          "privateKeyContent": "",
          "privateCertContent": "",
          "apiV3Key": ""
        },
        status: 0,
        statusChecked: false,
        remark: ''
      }
    },
    onClose() {
      this.$emit('closeDrawer') // 通知 父组件 关闭 弹窗
    }
  }
}
</script>